import { useQuery } from "@tanstack/react-query";
import { collection, getDocs, query } from "firebase/firestore";
import { useParams } from "react-router";
import { Container, Loader, Table } from "../../components";
import { firestore } from "../../firebase";
import styles from "./styles.module.scss";

interface Participant {
  category: string;
  categoryName: string;
  competitionId: string;
  horseName: string;
  name: string;
}

const CompetitionList: React.FC = () => {
  const { id } = useParams();

  const { data: participants, isLoading } = useQuery({
    queryKey: ["competitions", id, "participants"],
    queryFn: async () => {
      if (!id) return;

      const q = query(
        collection(firestore, "competitions", id, "participants")
      );
      const participants = await getDocs(q);

      return participants.docs.map((doc) => doc.data() as Participant);
    },
    enabled: !!id,
  });

  return (
    <div className={styles.container}>
      <Container>
        {isLoading ? (
          <div className={styles.loaderContainer}>
            <Loader />
          </div>
        ) : (
          <>
            <div className={styles.sectionTitle}>Inscritos</div>
            {participants?.length ? (
              <div className={styles.usersTable}>
                <Table responsive>
                  <Table.THead>
                    <Table.TR>
                      <Table.TH>Nombre y apellidos</Table.TH>
                      <Table.TH>Nombre del caballo</Table.TH>
                      <Table.TH>Categoría</Table.TH>
                    </Table.TR>
                  </Table.THead>
                  <Table.TBody>
                    {participants.map((item, index) => (
                      <Table.TR key={index}>
                        <Table.TD>{item.name}</Table.TD>
                        <Table.TD>{item.horseName}</Table.TD>
                        <Table.TD>{item.categoryName}</Table.TD>
                      </Table.TR>
                    ))}
                  </Table.TBody>
                </Table>
              </div>
            ) : (
              <div className={styles.emptyState}>
                Todavía no hay ningún participante
              </div>
            )}
          </>
        )}
      </Container>
    </div>
  );
};

export { CompetitionList };
