import { useQuery } from "@tanstack/react-query";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import React from "react";
import { useParams } from "react-router-dom";
import { Container, Loader, Table } from "../../components";
import { firestore } from "../../firebase";
import { useCategories } from "../../services";
import { UserResult, parseCompetition, parseResult } from "../../utils";
import styles from "./styles.module.scss";

const CompetitionResult: React.FC = () => {
  const { id, categoryId } = useParams();

  const { data: competition, isLoading } = useQuery({
    queryKey: ["competitions", id],
    queryFn: async () => {
      if (!id) return;

      const docRef = doc(firestore, "competitions", id);
      const docSnap = await getDoc(docRef);

      return parseCompetition(docSnap);
    },
    enabled: !!id,
  });

  const categories = useCategories();

  const { data: userResults } = useQuery({
    queryKey: ["competitions", id, "results", categoryId],
    queryFn: async () => {
      if (!id || !categoryId) return;

      const q = query(
        collection(firestore, "results"),
        where("competitionId", "==", id),
        where("category", "==", categoryId)
      );
      const results = await getDocs(q);

      return results.docs.map(
        (doc) => ({ id: doc.id, ...doc.data() } as UserResult)
      );
    },
    enabled: !!id && !!categoryId,
  });

  const category = competition?.resultCategories?.find(
    (item) => item.id === categoryId
  );

  const competitionCategory = categories.data?.find(
    (item) => item.id === competition?.category
  );

  return (
    <div className={styles.container}>
      <Container>
        <div className={styles.sectionTitle}>Resultados {category?.name}</div>
        {isLoading ? (
          <div className={styles.loaderContainer}>
            <Loader />
          </div>
        ) : userResults && category ? (
          <Table key={category.id} responsive>
            <Table.THead>
              <Table.TR>
                <Table.TH>O.S.</Table.TH>
                <Table.TH>Caballo</Table.TH>
                <Table.TH>Jinete</Table.TH>
                <Table.TH>Resultados</Table.TH>
                <Table.TH>Media</Table.TH>
                <Table.TH>Penalidad</Table.TH>
                <Table.TH>%</Table.TH>
                <Table.TH>Clasificación</Table.TH>
              </Table.TR>
            </Table.THead>
            <Table.TBody>
              {parseResult(userResults, category)
                .sort((a, b) =>
                  competitionCategory?.reversed
                    ? a.percentage - b.percentage
                    : b.percentage - a.percentage
                )
                .map((result, index) => (
                  <Table.TR key={index}>
                    <Table.TD>{result.exitOrder}</Table.TD>
                    <Table.TD>{result.horse}</Table.TD>
                    <Table.TD>{result.name}</Table.TD>

                    <Table.TD>
                      <div>
                        <b>{category.judge1}:</b> ({result.judge1.position}){" "}
                        {result.judge1.score.toFixed(3)}
                      </div>
                      {category.judge2 && result.judge2 ? (
                        <div>
                          <b>{category.judge2}:</b> ({result.judge2.position}){" "}
                          {result.judge2.score.toFixed(3)}
                        </div>
                      ) : null}

                      {category.judge3 && result.judge3 ? (
                        <div>
                          <b>{category.judge3}:</b> ({result.judge3.position}){" "}
                          {result.judge3.score.toFixed(3)}
                        </div>
                      ) : null}
                    </Table.TD>

                    <Table.TD>{result.average.toFixed(3)}</Table.TD>
                    <Table.TD>{result.penalty.toFixed(3)}</Table.TD>
                    <Table.TD>{result.percentage.toFixed(3)}</Table.TD>
                    <Table.TD>{index + 1}</Table.TD>
                  </Table.TR>
                ))}
            </Table.TBody>
          </Table>
        ) : (
          <div>Todavía no hay ningún resultado</div>
        )}
      </Container>
    </div>
  );
};

export { CompetitionResult };
