import { useQuery } from "@tanstack/react-query";
import { DocumentData, collection, getDocs, query } from "firebase/firestore";
import { firestore } from "../firebase";
import { Category, CategoryData } from "./types";

const parseCategory = (doc: DocumentData): Category => {
  return {
    ...(doc.data() as CategoryData),
    reversed: doc.get("reversed") || false,
    id: doc.id,
  };
};

const useCategories = () =>
  useQuery({
    queryKey: ["categories"],
    queryFn: async () => {
      const q = query(collection(firestore, "categories"));
      const docs = await getDocs(q);

      const categories: Category[] = [];
      docs.forEach((doc) => {
        categories.push(parseCategory(doc));
      });

      return categories;
    },
  });

export { useCategories };
