import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { collection, getDocs, query, where } from "firebase/firestore";
import sanitizeHtml from "sanitize-html";
import { Container } from "../../components";
import { firestore } from "../../firebase";
import styles from "./styles.module.scss";

const defaultOptions: sanitizeHtml.IOptions = {
  allowedTags: [
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "div",
    "ol",
    "ul",
    "li",
    "sub",
    "sup",
    "blockquote",
    "b",
    "i",
    "u",
    "s",
    "em",
    "strong",
    "a",
    "p",
    "br",
    "span",
    "img",
  ],
  allowedAttributes: {
    a: ["href"],
    span: ["class"],
    img: ["src"],
    div: ["class"],
  },
  selfClosing: ["img", "br"],
  allowedSchemes: ["data", "http"],
};

const sanitize = (dirty: string) => ({
  __html: sanitizeHtml(dirty, defaultOptions),
});

const NewsPage: React.FC = () => {
  const { data: news } = useQuery({
    queryKey: ["news"],
    queryFn: async () => {
      const newsRef = query(
        collection(firestore, "news"),
        where("deleted", "!=", true)
      );
      const news = await getDocs(newsRef);

      return news.docs.map((doc) => ({
        id: doc.id,
        title: doc.get("title"),
        description: doc.get("description"),
        date: doc.get("date").toDate(),
      }));
    },
  });

  const filteredNews = news?.sort((a, b) => {
    return dayjs(b.date).diff(dayjs(a.date));
  });

  return (
    <div className={styles.container}>
      {filteredNews?.map((news) => (
        <Container key={news.id} className={styles.cont}>
          <div className="">
            <h2>{news.title}</h2>
            <div className={styles.date}>
              {dayjs(news.date).format("DD/MM/YYYY HH:mm")}
            </div>

            <div
              className={styles.description}
              dangerouslySetInnerHTML={sanitize(news.description)}
            />
          </div>
        </Container>
      ))}
    </div>
  );
};

export { NewsPage };
