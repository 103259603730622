import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import React from "react";
import { useNavigate, useParams } from "react-router";
import CalendarIcon from "../../assets/calendar";
// import LinkIcon from "../../assets/link";
import { useQuery } from "@tanstack/react-query";
import LocationIcon from "../../assets/location";
import { Accordion, Loader } from "../../components";
import { firestore } from "../../firebase";
import { Category, Competition } from "../../types";
import { dayjs, formatDateRange, parseCompetition } from "../../utils";
import styles from "./styles.module.scss";

interface CategoryInfo extends Category {
  links: {
    name: string;
    url: string;
  }[];
  nextCompetitions: Competition[];
  pastCompetitions: { [key: string]: Competition[] };
}

const CategoryPage: React.FC = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const { data: category, isLoading } = useQuery({
    queryKey: ["category", id],
    queryFn: async () => {
      if (!id) {
        return;
      }

      const categoryRef = doc(firestore, "categories", id);
      const category = await getDoc(categoryRef);

      const competitions = await getDocs(
        query(
          collection(firestore, "competitions"),
          where("category", "==", category.ref),
          where("deleted", "!=", true)
        )
      );

      const parsed = competitions.docs.map((comp) => parseCompetition(comp));

      const nextCompetitions = parsed.filter((item) => {
        const referenceDate = item.dateEnd || item.date;
        return dayjs(referenceDate)
          .startOf("day")
          .isSameOrAfter(dayjs().startOf("day"));
      });

      const previousCompetitions = parsed.filter((item) => {
        const referenceDate = item.dateEnd || item.date;
        return dayjs(referenceDate)
          .startOf("day")
          .isBefore(dayjs().startOf("day"));
      });

      const pastCompetitions = previousCompetitions.reduce<{
        [key: string]: Competition[];
      }>((rv, x) => {
        const year = dayjs(x.date).format("YYYY");
        (rv[year] = rv[year] || []).push(x);
        return rv;
      }, {});

      return {
        id: category.id,
        ...category.data(),
        nextCompetitions,
        pastCompetitions,
      } as CategoryInfo;
    },
    enabled: !!id,
  });

  return (
    <div className={styles.container}>
      {isLoading ? (
        <div className={styles.loaderContainer}>
          <Loader />
        </div>
      ) : category ? (
        <div>
          <div
            className={styles.header}
            style={{ backgroundImage: `url(${category.image})` }}
          >
            <div className={styles.title}>{category.name}</div>
          </div>

          {/* <div className={styles.section}>
            <div className={styles.title}>Enlaces</div>
            {category.links.map((link) => (
              <a
                href={link.url}
                target="_blank"
                rel="nofollow noopener noreferrer"
                className={styles.link}
              >
                <LinkIcon className={styles.icon} /> {link.name}
              </a>
            ))}
            </div> */}

          {category.nextCompetitions.length ||
          Object.values(category.pastCompetitions).length ? (
            <>
              {category.nextCompetitions.length ? (
                <div className={styles.section}>
                  <div className={styles.title}>Próximas competiciones</div>

                  <div className={styles.competitions}>
                    {category.nextCompetitions.map((competition) => (
                      <div className={styles.col} key={competition.id}>
                        <div
                          className={styles.nextCompetition}
                          onClick={() =>
                            navigate(`/competition/${competition.id}`)
                          }
                        >
                          {competition.image ? (
                            <img
                              src={competition.image.url}
                              className={styles.image}
                              alt={competition.name}
                            />
                          ) : null}
                          <div className={styles.info}>
                            <div className={styles.name}>
                              {competition.name}
                            </div>
                            <div className={styles.date}>
                              <CalendarIcon className={styles.icon} />{" "}
                              {formatDateRange(
                                competition.date,
                                competition.dateEnd
                              )}
                            </div>
                            <div className={styles.location}>
                              <LocationIcon className={styles.icon} />{" "}
                              {competition.location}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : null}

              {Object.values(category.pastCompetitions).length ? (
                <div className={styles.section}>
                  <div className={styles.title}>Competiciones finalizadas</div>

                  {Object.entries(category.pastCompetitions)
                    .map(([key, item]) => (
                      <Accordion
                        title={`Año ${key}`}
                        competitions={item}
                        key={key}
                      />
                    ))
                    .sort((a, b) => Number(b.key) - Number(a.key))}
                </div>
              ) : null}
            </>
          ) : (
            <div className={styles.noData}>
              Todavía no hay ninguna competición
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

export { CategoryPage };
