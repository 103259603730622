import { useQuery } from "@tanstack/react-query";
import { collection, getDocs, query, where } from "firebase/firestore";
import { useNavigate } from "react-router";
import ChevronForward from "../../assets/chevron-forward";
import { firestore } from "../../firebase";
import { Category } from "../../types";
import style from "./styles.module.scss";

const Home: React.FC = () => {
  const navigate = useNavigate();

  const { data: categories } = useQuery({
    queryKey: ["categories"],
    queryFn: async () => {
      const categoriesRef = query(
        collection(firestore, "categories"),
        where("deleted", "!=", true)
      );
      const categories = await getDocs(categoriesRef);

      return categories.docs
        .map((doc) => ({ id: doc.id, ...doc.data() } as Category))
        .sort((a, b) => a.order - b.order);
    },
  });

  return (
    <div className={style.container}>
      <div className={style.categories}>
        {categories?.map((category) => (
          <div
            key={category.id}
            className={style.col}
            onClick={() => navigate(`/category/${category.id}`)}
          >
            <div
              className={style.horseCategory}
              style={{
                backgroundImage: `url(${category.image})`,
              }}
            >
              <div className={style.title}>{category.name}</div>
              <div className={style.viewResults}>
                Ver resultados <ChevronForward className={style.icon} />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export { Home };
